import React, { useEffect, useRef, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import PrincipalViews from '../../views/PrincipalViews'
import api from '../../api'
import { DIRECTIONS } from '../../const'
import OrdersTable from '../../views/OrdersTable'
import { handleRequestError } from '../../utils'
import Preloader from '../../views/Preloader'

const PrincipalPortfolio = ({ orderListHead }) => {
  const [principal, setPrincipal] = useState({})
  const [orderListBody, setOrdersList] = useState([])
  const [fieldToSort, setFieldToSort] = useState('')
  const [sortDirection, setSortDirection] = useState(DIRECTIONS.asc)
  const [isFetching, setIsFetching] = useState(false)

  const { principalId } = useParams()
  const { getPrincipalById } = api.entityApi
  const { getOrdersList } = api.ordersApi
  const mounted = useRef()
  const navigate = useNavigate()

  const requestAndConvertOrders = (params = {}) => {
    setIsFetching(true)
    return getOrdersList({ principal: principalId, ...params })
      .then((data) => {
        setOrdersList(data.serializedData)
      })
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }

  const sortOrdersList = (field) => {
    setFieldToSort(field)
    setSortDirection(sortDirection === DIRECTIONS.asc ? DIRECTIONS.desc : DIRECTIONS.asc)
  }

  const modifiedOrderListHead = orderListHead.map(item => {
    return {
      ...item,
      cells: item.cells.map(cell => {
        return {
          ...cell,
          onClick: () => sortOrdersList(cell.sortName)
        }
      })
    }
  })

  useEffect(() => {
    Promise.all([getPrincipalById(principalId), requestAndConvertOrders()])
      .then(([principalData]) => setPrincipal(principalData))
      .catch(err => handleRequestError(err, navigate))
  }, [])

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      return
    }
    requestAndConvertOrders({
      ordering: sortDirection === DIRECTIONS.asc
        ? fieldToSort
        : `-${fieldToSort}`
    })

  }, [sortDirection, fieldToSort])

  return (
    <>
      <PrincipalViews.Header principal={principal} />
      {isFetching ?
        <Preloader />
        :
        <OrdersTable
          tableHead={modifiedOrderListHead}
          tableBody={orderListBody}
          sortDirection={sortDirection}
          fieldToSort={fieldToSort}
        />
      }
    </>
  )
}

export default PrincipalPortfolio
