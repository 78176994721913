import { React, useState } from 'react';
import classNames from 'classnames';
import { GxForm } from '@garpix/garpix-web-components-react';
import Title from '../Title';
import Input from '../Input';
import Button from '../Button';
import ErrorField from '../ErrorField'
import Modal from '../ModalCreator';
import style from './styles/orderListExport.module.scss';

const OrderListExport = ({
  formikExport = {},
  resetForm,
  handleTakeBlank,
  isRendered,
  className,
  isOpen,
  minDate,
  maxDate,
  minID,
  maxID,
  ...props
}) => {

  const [isMinDateChanged, setIsMinDateChanged] = useState(false)
  const [isMaxDateChanged, setIsMaxDateChanged] = useState(false)
  const [isMinIDChanged, setIsMinIDChanged] = useState(false)
  const [isMaxIDChanged, setIsMaxIDChanged] = useState(false)
  const [typeDateMin, setTypeDateMin] = useState('text')
  const [typeDateMax, setTypeDateMax] = useState('text')
  let placeholderDateMin = new Date(formikExport.values.dateMin)
  placeholderDateMin = placeholderDateMin.toLocaleDateString()
  let placeholderDateMax = new Date(formikExport.values.dateMax)
  placeholderDateMax = placeholderDateMax.toLocaleDateString()

  const exportHandleChange = (fieldName, fieldValue) => {
    if (fieldName === 'dateMin') {
      setIsMinDateChanged(String(fieldValue) !== String(minDate))
    }
    if (fieldName === 'dateMax') {
      setIsMaxDateChanged(String(fieldValue) !== String(maxDate))
    }
    if (fieldName === 'idMin') {
      setIsMinIDChanged(String(fieldValue) !== String(minID))
    }
    if (fieldName === 'idMax') {
      setIsMaxIDChanged(String(fieldValue) !== String(maxID))
    }
  }

  const handleResetForm = () => {
    formikExport.resetForm()
    setTypeDateMin('text')
    setTypeDateMax('text')
    setIsMinDateChanged(false)
    setIsMaxDateChanged(false)
    setIsMinIDChanged(false)
    setIsMaxIDChanged(false)    
  }

  return (
    <Modal isOpen={isOpen}>
      <div
        className={classNames(
          style.container,
          { [className]: !!className }
        )}
        {...props}
      >
        <Title
          className={style.ttl}
          level='2'
          projectVariant='title_section'
        >
          Выгрузка
        </Title>
        <GxForm
          onGx-submit={formikExport.handleSubmit}
        >
          <div>
          <fieldset className={style.block}>
              <Title
                className={style.block__ttl}
                level='3'
                projectVariant='title'
              >
                Дата заявки
              </Title>
              <div className={style.block__range_with_labels}>
                <Title
                  className={style.block__label} level='4'
                  projectVariant='title'
                >
                  C
                </Title>
                <Input
                  name='dateMin'
                  className={classNames([
                    style.block__ie,
                    style.block__ieYear,
                    style.block__ieYearFrom
                  ])}
                  type={typeDateMin}
                  pattern='dd.mm.YYYY'
                  placeholder={placeholderDateMin}
                  onGx-change={(e) => {
                    formikExport.setFieldValue('dateMin', e.target.value);
                    exportHandleChange('dateMin', e.target.value);
                  }}
                  onGx-focus={() => {
                    setTypeDateMin('date')
                  }}
                  min={minDate}
                  max={maxDate}
                  disabled={isMinIDChanged || isMaxIDChanged}
                  helpText={<ErrorField
                    slot='help-text'
                    message={formikExport.errors.dateMin}
                  />}
                />
                <Title
                  className={style.block__label} level='4'
                  projectVariant='title'
                >
                  До
                </Title>
                <Input
                  name='dateMax'
                  className={classNames([
                    style.block__ie,
                    style.block__ieYear,
                    style.block__ieYearUntil
                  ])}
                  type={typeDateMax}
                  placeholder={placeholderDateMax}
                  min={minDate}
                  max={maxDate}
                  onGx-change={(e) => {
                    formikExport.setFieldValue('dateMax', e.target.value);
                    exportHandleChange('dateMax', e.target.value);
                  }}
                  onGx-focus={() => {
                    setTypeDateMax('date')
                  }}
                  disabled={isMinIDChanged || isMaxIDChanged}
                  helpText={<ErrorField
                    slot='help-text'
                    message={formikExport.errors.dateMax}
                  />}
                />
              </div>
            </fieldset>
            <fieldset className={style.block}>
              <Title
                className={style.block__ttl}
                level='3'
                projectVariant='title'
              >
                Номер заявки
              </Title>
              <div className={style.block__range_with_labels}>
                <Title
                  className={style.block__label} level='4'
                  projectVariant='title'
                >
                  C
                </Title>
                <Input
                  name='idMin'
                  className={classNames([
                    style.block__ie,
                    style.block__ieYear,
                    style.block__ieYearFrom
                  ])}
                  type='number'
                  min={minID}
                  max={maxID}
                  placeholder={formikExport.values.idMin}
                  onGx-input={formikExport.handleChange}
                  onGx-change={(e) => {
                    formikExport.setFieldValue('idMin', e.target.value);
                    exportHandleChange('idMin', e.target.value);
                  }}
                  disabled={isMinDateChanged || isMaxDateChanged}
                  helpText={<ErrorField
                    slot='help-text'
                    message={formikExport.errors.idMin}
                  />}
                />
                <Title
                  className={style.block__label} level='4'
                  projectVariant='title'
                >
                  По
                </Title>
                <Input
                  name='idMax'
                  className={classNames([
                    style.block__ie,
                    style.block__ieYear,
                    style.block__ieYearUntil
                  ])}
                  type='number'
                  min={minID}
                  max={maxID}
                  placeholder={formikExport.values.idMax}
                  onGx-change={(e) => {
                    formikExport.setFieldValue('idMax', e.target.value);
                    exportHandleChange('idMax', e.target.value);
                  }}
                  disabled={isMinDateChanged || isMaxDateChanged}
                  helpText={<ErrorField
                    slot='help-text'
                    message={formikExport.errors.idMax}
                  />}
                />
              </div>
            </fieldset>
            <div className={classNames([
              style.block,
              style.controls
            ])}>
              <Button
                className={classNames([
                  style.block__control,
                  style.block__reset
                ])}
                projectVariant='button_transparent'
                type='reset'
                onClick={handleResetForm}
                full
              >
                Сбросить
              </Button>
              <Button
                className={classNames([
                  style.block__control,
                  style.block__reset
                ])}
                projectVariant='button_transparent'
                type='reset'
                onClick={handleTakeBlank}
                full
              >
                Скачать шаблон
              </Button>              
              <Button
                className={classNames([
                  style.block__control,
                  style.block__submit
                ])}
                type='submit'
                full
              >
                Применить
              </Button>
            </div>
          </div>
        </GxForm>
      </div>
    </Modal>
  )
}

export default OrderListExport;