import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import CollapseTable from '../../views/CollapseTable'
import PrincipalViews from '../../views/PrincipalViews'
import Title from '../../views/Title'
import api from '../../api'
import { handleRequestError } from '../../utils'
import Preloader from '../../views/Preloader'
// import style from './index.module.scss'

const PrincipalFinreporting = () => {
  const [report, setReport] = useState({})
  const [principal, setPrincipal] = useState({})
  const [finReport, setFinReport] = useState({})
  const [finOW, setFinOW] = useState({})
  const [isFetching, setIsFetching] = useState(false)

  const { getPrincipalById } = api.entityApi
  const { getOrderAccountingForms, getOrderOW } = api.ordersApi
  const { principalId } = useParams()
  const navigate = useNavigate()
  const getURL = true

  useEffect(() => {
    setIsFetching(true)
    getPrincipalById(principalId)
    .then(dataPrincipal => {
      setPrincipal(dataPrincipal);
    })
    .catch(err => handleRequestError(err, navigate))
    Promise.all([
      getOrderOW({ supplier: principalId }),
      getOrderAccountingForms({ principal: principalId })
    ])
      .then(([owData, finReportData]) => {
        setFinOW(owData[0])
        setFinReport(finReportData)
      })
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }, [])

  useEffect(() => {
    if (Object.keys(finReport).length > 0) {
      const rows = finReport.odds[0].rows.map(row => {
        if (row[0].type) {
          return [{
            ...row[0],
            '2020': finOW ? finOW[row[0].type] : '',
          }]
        }
        return row
      });
      setReport({
        ...finReport,
        odds: [{ ...finReport.odds[0], rows }],
      })
    }
  }, [finReport])

  return (
    <>
      <PrincipalViews.Header 
        principal={principal} 
        getURL={getURL}
      />
      {isFetching ?
        <Preloader />
        :
        report?.balance
        &&
        <section>
          <Title projectVariant='title_section-finreporting' level='2'>
            Баланс
          </Title>
          {report?.balance?.map((elem, idx) => {
            const key = `collapse-balance-${idx}`
            return (
              <CollapseTable key={key} tableData={elem} />
            )
          }
          )}
          <Title projectVariant='title_section-finreporting' level='2'>
            ОПУ
          </Title>
          {report?.opu?.map((elem, idx) => {
            const key = `collapse-opu-${idx}`
            return (
              <CollapseTable key={key} tableData={elem} />
            )
          }
          )}
          <Title projectVariant='title_section-finreporting' level='2'>
            Коэффициенты
          </Title>
          {report?.odds?.map((elem, idx) => {
            const key = `collapse-odds-${idx}`
            return (
              <CollapseTable key={key} tableData={elem} />
            )
          }
          )}
        </section>
      }
    </>
  )
}


export default PrincipalFinreporting
