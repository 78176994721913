import logo from './logo/logo.svg'
import logoLg from './logo/logo-lg.svg'
import alert from './icons/alert.svg'
import arrowBack from './icons/arrow_back.svg'
import arrowUp from './icons/arrow_up.svg'
import arrowDown from './icons/arrow_down.svg'
import auctionHammer from './icons/auction_hammer.svg'
import bank from './icons/bank.svg'
import bellIndicator from './icons/bell_indicator.svg'
import bell from './icons/bell.svg'
import book from './icons/book.svg'
import businessman from './icons/businessman.svg'
import caseIcon from './icons/case.svg'
import chatIndicator from './icons/chat_indicator.svg'
import chat from './icons/chat.svg'
import сhart from './icons/сhart.svg'
import connection from './icons/connection.svg'
import creditScore from './icons/credit_score.svg'
import goszakupki from './icons/goszakupki.svg'
import documentCheck from './icons/document_check.svg'
import documentIcon from './icons/document.svg'
import documentIcon2 from './icons/document2.svg'
import forbidden from './icons/forbidden.svg'
import graph from './icons/graph.svg'
import group from './icons/group.svg'
import header from './icons/header.svg'
import history from './icons/history.svg'
import list from './icons/list.svg'
import purse from './icons/purse.svg'
import question from './icons/question.svg'
import rated from './icons/rated.svg'
import slidersHorizontal from './icons/sliders_horizontal.svg'
import tablet from './icons/tablet.svg'
import userNoun from './icons/user_noun.svg'
import userNotAllowed from './icons/user_not_allowed.svg'
import user from './icons/user.svg'
import checkCircle from './icons/check_circle.svg'
import clip from './icons/clip.svg'
import del from './icons/delete.svg'
import download from './icons/download.svg'
import downloadActive from './icons/downloadActive.svg'
import pen from './icons/pen.svg'
import cancel from './icons/cancel.svg'
import search from './icons/search.svg'
import submit from './icons/submit.svg'
import plus from './icons/plus.svg'
import principalAlert from './icons/principal_alert.svg'
import matrix from './icons/matrix.svg'
import userAvatar from './user_avatar.jpg'
import loader from './loader.svg'

export {
    loader,
    logo,
    logoLg,
    alert,
    arrowBack,
    arrowUp,
    arrowDown,
    auctionHammer,
    bank,
    bellIndicator,
    bell,
    book,
    businessman,
    caseIcon,
    chatIndicator,
    chat,
    сhart,
    connection,
    creditScore,
    goszakupki,
    documentCheck,
    documentIcon,
    documentIcon2,
    forbidden,
    graph,
    group,
    header,
    history,
    list,
    purse,
    question,
    rated,
    slidersHorizontal,
    tablet,
    userNoun,
    userNotAllowed,
    user,
    checkCircle,
    clip,
    del,
    download,
    downloadActive,
    cancel,
    pen,
    search,
    plus,
    submit,
    principalAlert,
    matrix,
    userAvatar
}