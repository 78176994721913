/* eslint-disable no-plusplus */
/* eslint-disable no-undef */
import { toast } from 'react-toastify';
import { STATUSES_ID, STATUSES_STATE, PATHS, ROLES } from '../const';

export const setCookie = (name, value, days) => {
  let expires = '';

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${(value || '') + expires}; path=/`;
}

export const getCookie = (name) => {
  try {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  } catch (e) {
    return null;
  }
}

export const removeCookie = (name) => {
  document.cookie = `${name}=; Max-Age=-99999999; path=/`;
}

export const initialsName = (firstName, lastName, patronymic) => {
  const getFirstLetter = (name) => {
    if (name) return `${name[0]}.`
    return ''
  }
  return `${lastName || ''} ${getFirstLetter(firstName)} ${getFirstLetter(patronymic)}`;
}

export const getUrls = (name, params = {}) => {
  if (!PATHS[name]) return null
  let { path } = PATHS[name];
  Object.keys(params).forEach(key => {
    const value = params[key]
    path = path.replace(`:${key}`, value)
  })
  return path
}

export const getStatusColor = (statusName) => {
  if (!statusName) return 'red'

  const splittedStatus = statusName.split(' ')
  if (splittedStatus.includes('дозапрос')) return 'yellow'
  if (splittedStatus.includes('отказ')) return 'red'
  return 'green'
}

const getAction = (action) => {
  switch (action) {
    case 'update':
      return 'Изменение';
    case 'create':
      return 'Создание';
    default:
      return '';
  }
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

export const getLogAction = (actionModel, field) => {
  return `${getAction(actionModel)}${field ? `: ${capitalizeFirstLetter(field)}` : ''}`;
};

export const getDocumentTitle = (type) => {
  switch (type) {
    case 'REGULATION':
      return 'Правоустанавливающие документы'
    case 'FINANCIAL':
      return 'Финансовые документы'
    case 'BUSINESS':
      return 'Сделка'
    case 'LICENSES':
      return 'Лицензии/СРО'
    case 'REPORTS':
      return 'Отчётность'
    case 'PASSPORT OF THE BENEFICIARY':
      return 'Паспорт Бенефициара'
    case 'PASSPORT OF THE GENERAL DIRECTOR':
      return 'Паспорт Генерального Директора'
    case 'APPLICATION FOR GRANT':
      return 'Заявление на предоставление БГ'
    case 'OTHER':
      return 'Прочие'
    default:
      return '';
  }
};

export const getDocumentId = (type, id) => {
  switch (type) {
    case 'OTHER':
      return 999
    default:
      return id+1;
  }
};

export const getDocumentsByType = (types, documentsArray) => {
  const typesWithDocuments = types.map(type => {
    if (type.type === 'OTHER') {
      const documents = documentsArray.filter(doc => doc.document_type?.id === type.id);
      return { ...type, documents };
    }
    const document = documentsArray.find(doc => doc.document_type?.id === type.id);
    return { ...type, document };
  });

  const groupedTypes = Array.from(typesWithDocuments.reduce((m, group) =>
    m.set(group.type, [...(m.get(group.type) || []), group]), new Map
  ), ([type, documents]) => ({ type, documents })
  );

  const result = groupedTypes.map((group, id) => ({ ...group, id: getDocumentId(group.type, id), title: getDocumentTitle(group.type) }));;

  const sortFunc = (x, y) => {
    /* eslint-disable no-else-return */
    if (Number(x.id) > Number(y.id)) {
      return 1;
    } else if (Number(x.id) < Number(y.id)) {
      return -1;
    }
    return 0;
  }
  result.sort(sortFunc);

  return result;
};

export const convertStatusName = (status) => {
  if (!status) return '-'

  const {
    QUOTE_AUTO,
    QUOTE_INDIVIDUAL,
    QUOTE_AUCTION,
    UNDERWRITING_IN_PROGRESS,
    UNDERWRITING_A_NEW_APPLICATION,
    UNDERWRITING_REFUSAL,
    DOCUMENTS_REQUERY,
    DOCUMENTS_REFUSAL,
    GUARANTEE_ISSUE_REQUESTED,
    GUARANTEE_ISSUED_PAYMENT_EXPECTED,
    GUARANTEE_DISCLAIMER,
  } = STATUSES_STATE

  const inProgressStatuses = [QUOTE_AUTO, QUOTE_INDIVIDUAL, QUOTE_AUCTION, UNDERWRITING_IN_PROGRESS]
  const newOrderStatuses = [UNDERWRITING_A_NEW_APPLICATION]
  const orderRejectedByUWStatuses = [UNDERWRITING_REFUSAL]
  const docsRequeryStatuses = [DOCUMENTS_REQUERY]
  const orderRejectedStatuses = [DOCUMENTS_REFUSAL, GUARANTEE_DISCLAIMER]
  const guarantyRequestedStatuses = [GUARANTEE_ISSUE_REQUESTED]
  const guarantyExpectedStatuses = [GUARANTEE_ISSUED_PAYMENT_EXPECTED]

  if (inProgressStatuses.includes(status)) return 'В работе'
  if (newOrderStatuses.includes(status)) return 'Новая'
  if (orderRejectedByUWStatuses.includes(status)) return 'Заявка отменена андеррайтером'
  if (docsRequeryStatuses.includes(status)) return 'Дозапрос документов'
  if (orderRejectedStatuses.includes(status)) return 'Заявка отменена'
  if (guarantyRequestedStatuses.includes(status)) return 'Гарантия запрошена'
  if (guarantyExpectedStatuses.includes(status)) return 'Гарантия выпущена'

  return STATUSES_ID[status]
}

export const handleRequestError = (err, navigate) => {
  if (err.response?.status === 400 && err.response.data instanceof Array) {
    Object.values(err.response.data).forEach(error => {
      error.forEach(item => toast.error(item || 'Неправильно введены данные'))
    })
  }
  if (err.response?.status === 403) {
    removeCookie('bg_token')
    setTimeout(() => navigate(PATHS.AUTHORIZATION.path), 1)
  }
  if (err.response?.status === 404) {
    navigate(PATHS.ERROR404.path);
  }
  if (err.response?.status === 500) {
    navigate(PATHS.ERROR500.path);
  }
}

export const isRoleJuniorUW = (user) => user?.role === ROLES.JUNIOR_UW

export const getFinReportByCode = (data, code) => (data.find(item => item.code === code).end_value);

export const numberWithSpaces = (number, withFloat = false) => {
  if (number === undefined || number === null) return ''

  const [int, float] = Number(number).toFixed(2).toString().split('.')
  const formattedNum = int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

  if (withFloat) return `${formattedNum}.${float ?? '00'}`
  return formattedNum
}

export const formatNumberToSend = (value = '') => {
  if (value) {
    const formatted = String(value).replaceAll(' ', '').replace(',', '.')
    return formatted
  }
  return value
}

export const formatPercentages = (num, withFloat = false) => {
  const formatted = String(num).replaceAll(' ', '').replace(',', '.')
  if (withFloat) return Number(formatted).toFixed(2)

  const [int, float] = formatted.split('.')
  if (!float) return int
  return `${int}${float?.slice(0, 2) === '00' ? '' : `.${float?.slice(0, 2)}`}`
}

export const getUniqArray = (array) => {
  const set = new Set()
  return array.filter(item => {
    const key = JSON.stringify(item)
    return set.has(key) ? false : set.add(key)
  })
}
