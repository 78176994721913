import React, { useState } from 'react';
import classNames from 'classnames';
import { GxTooltip } from '@garpix/garpix-web-components-react'
import ButtonIcon from '../../ButtonIcon';
import { goszakupki, list } from '../../../images'
import style from './index.module.scss';

const PurchaseShortInfo = ({
  purchase,
  pnt,
  fz,
  lot,
  className,
  ...props
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const toggleTooltipOpen = () => setIsTooltipOpen(!isTooltipOpen)
  const handleOpenURL = () => {
    const newTab = window.open(purchase?.zakupki_gov_url, '_blank', 'noopener,noreferrer');
    if ( newTab ) newTab.opener = null;
  }
  return (
    <section
      className={classNames({
        [style.section]: true,
        [className]: !!className
      })}
      {...props}
    >
      <div className={style.block}>
        <span className={style.key}>Предмет закупки</span>
        <span className={classNames([
          style.value,
          style.purchaseObject
        ])}>
          <span className={style.textOverflow}>{purchase?.purchase_object}</span>
          {purchase?.purchase_object && <GxTooltip
            trigger='manual'
            content={purchase?.purchase_object}
            open={isTooltipOpen}
            className={style.tooltipWrapper}
            placement='right'
          >
            <ButtonIcon
              className={style.btnIcon}
              onClick={toggleTooltipOpen}
              ariaLabel={purchase?.purchase_object}
              iconSrc={list}
              onBlur={() => setIsTooltipOpen(false)}
            />
          </GxTooltip>}
        </span>
      </div>
      <div className={style.block}>
        <span className={style.key}>Реестровый номер торгов (РНТ)</span>
        <span className={style.value}>{pnt || '-'}</span>
      </div>
      <div className={style.block}>
        <span className={style.key}>Тип гарантии</span>
        <span className={style.value}>{fz || '-'}</span>
      </div>
      <div className={style.block}>
        <span className={style.key}>Лот</span>
        <span className={style.value}>{lot?.number || '-'}</span>
      </div>
      <div style={{justifyContent: 'end', display: 'flex',}} >
        <ButtonIcon
          classNameIcon = 'buttonIcon__icon145'
          iconSrc={goszakupki}
          disabled={purchase?.zakupki_gov_url === null || purchase?.zakupki_gov_url === undefined}
          onClick={handleOpenURL}
        />
      </div>
    </section>
  )
}

export default PurchaseShortInfo;