import { React } from 'react';
import Title from '../../Title/index';
import OrdersTable from '../../OrdersTable/index';
import ButtonIcon from '../../ButtonIcon';
import { download, downloadActive, slidersHorizontal } from '../../../images';
import Preloader from '../../Preloader';
import Paginator from '../../Paginator/index';
import styles from './styles/index.module.scss';

const List = ({
  title = 'Мои заявки',
  tableHead,
  tableBody,
  sortDirection,
  fieldToSort,
  toggleModal,
  isFetching = false,
  paginateData=null,
  toggleExportModal=null,
  children,
}) => {
  return (
    <>
      <div className={styles.hgroup}>
        <Title
          className={styles.hgroup__title}
          level='1'
          projectVariant='title_section'
        >
          {title}
        </Title>
        {toggleExportModal ? 
          <div className={styles.buttons}>
            <ButtonIcon
              className={styles.hgroup__btnFilter}
              iconSrc={tableBody.length === 0 ? download : downloadActive}
              ariaLabel='Выгрузка в excel'
              title='Выгрузка в excel'
              disabled={tableBody.length === 0}
              onClick={toggleExportModal}
            />
            <ButtonIcon
              className={styles.hgroup__btnFilter}
              iconSrc={slidersHorizontal}
              ariaLabel='Открыть модальное окно фильтрации'
              title='Открыть модальное окно фильтрации'
              onClick={toggleModal}
            />
          </div>        
          :
          <ButtonIcon
            className={styles.hgroup__btnFilter}
            iconSrc={slidersHorizontal}
            ariaLabel='Открыть модальное окно фильтрации'
            title='Открыть модальное окно фильтрации'
            onClick={toggleModal}
          />
        } 
      </div>
      <section className={styles.orderList}>
        {isFetching ?
          <Preloader />
          :
          <div>
            {paginateData ?
              <div>
                <div>
                  <Paginator paginateData={paginateData} />
                  <OrdersTable
                    tableHead={tableHead}
                    tableBody={tableBody}
                    sortDirection={sortDirection}
                    fieldToSort={fieldToSort}
                  />
                  <Paginator paginateData={paginateData} />
                </div>  
              </div>            
              :
              <OrdersTable
                tableHead={tableHead}
                tableBody={tableBody}
                sortDirection={sortDirection}
                fieldToSort={fieldToSort}
              />
            }
          </div>
        }
      </section>
      {children}
    </>
  )
}

export default List;
