import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { GxForm } from '@garpix/garpix-web-components-react';
import api from '../../api';
import OrderViews from '../../views/OrderViews';
import Button from '../../views/Button';
import Textarea from '../../views/Textarea';
import Title from '../../views/Title';
import { arrowDown } from '../../images';
import { getDocumentsByType, handleRequestError } from '../../utils';
import style from './index.module.scss';
import { STATUSES_STATE, ORDER_COMMENTS_PAGES } from '../../const';

const OrderDocuments = () => {
  const [order, setOrder] = useState({});
  const [documents, setDocuments] = useState([]);
  const [types, setTypes] = useState([]);
  const [checked, setChecked] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const {
    getOrderById,
    getOrdersDocumentsList,
    getOrdersDocumentTypesList,
    deleteOrderDocument,
    updateOrderDocument,
    uploadOrderFile,
    postOrderComment,
  } = api.ordersApi;
  const { orderId } = useParams();
  const navigate = useNavigate()

  const initialValues = {
    content: '',
  }

  const toggleShowForm = () => setShowForm(!showForm);

  const toggleCheck = (event) => {
    const id = +event.target.name

    if (checked.includes(id)) {
      const filteredDocuments = checked.filter(item => item !== id)
      setChecked(filteredDocuments)
    } else {
      setChecked(prev => [...prev, id])
    }
  };

  const handleDelete = (id) => {
    const handleDeleteOrderDocument = () => deleteOrderDocument(id)
    return toast.promise(handleDeleteOrderDocument, {
      pending: 'Удаление документа...',
      success: 'Документ успешно удален',
    })
      .then(() => {
        const filteredDocuments = documents.filter(doc => doc.id !== id)
        setDocuments(filteredDocuments)
      })
      .catch(err => handleRequestError(err, navigate))
  }

  const requestDocuments = () => {
    return getOrdersDocumentsList({ order: orderId })
      .then(data => setDocuments(data))
      .catch(err => handleRequestError(err, navigate))
  }

  const onSubmit = (data) => {
    const { content } = data;
    const commentParams = { content, order: order?.id, page: ORDER_COMMENTS_PAGES.DOCUMENT }
    const hasValidDocuments = documents.filter(doc => checked.includes(doc.id)).some(doc => doc.is_valid)

    const promises = []
    checked.forEach(id => {
      promises.push(updateOrderDocument(id, { is_valid: !hasValidDocuments }))
    })

    return toast.promise(Promise.all(promises), {
      pending: 'Валидация...',
      success: 'Выделенные документы успешно валидированы',
    })
      .then(() => {
        toggleShowForm();
        return postOrderComment(commentParams)
      })
      .then(requestDocuments)
      .catch(err => handleRequestError(err, navigate))
  }

  const handleButtonForm = (data, resetForm) => {
    if (data.content.length > 0) {
      onSubmit(data);
      resetForm();
    } else {
      toggleShowForm();
    }
  };

  const handleUpload = async (file, type) => {
    const formData = new FormData()
    const data = {
      order: orderId,
      document_title: type.type === 'OTHER' ? file.name.split('.')[0] : type.title,
      document_type: type.id,
      is_valid: false,
    }
    formData.append('document', file)
    formData.append('data', JSON.stringify(data))

    const handleUploadOrderFile = () => uploadOrderFile(formData)
    return toast.promise(handleUploadOrderFile, {
      pending: 'Загрузка...',
      success: 'Документ успешно загружен',
    })
      .then(res => setDocuments([...documents, res]))
      .catch(err => handleRequestError(err, navigate))
  }

  useEffect(() => {
    Promise.all([
      getOrderById(orderId),
      getOrdersDocumentTypesList({ order: orderId }),
      requestDocuments(),
    ])
      .then(([orderData, dataTypes]) => {
        setOrder(orderData)
        setTypes(dataTypes)
      })
      .catch(err => handleRequestError(err, navigate))
  }, [])

  return (
    <>
      <OrderViews.Header
        orderId={order.id}
        sum={order.sum}
        principal={order.principal}
        fz={order.contest?.fz?.fz}
        status={order.state}
      />
      {order.state === STATUSES_STATE.QUOTE_AGREED &&
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
        >
          {({ handleSubmit, handleChange, values, resetForm }) => (
            <>
              <section className={style.controls}>
                <Button
                  className={style.btnValidate}
                  type='submit'
                  disabled={documents.filter(doc => checked.includes(doc.id)).some(doc => doc.is_valid)}
                  onClick={() => handleButtonForm(values, resetForm)}
                >
                  Валидировать
                </Button>
                <Button
                  className={style.btnDeny}
                  projectVariant='button_transparent'
                  type='submit'
                  disabled={documents.filter(doc => checked.includes(doc.id)).some(doc => !doc.is_valid)}
                  onClick={() => handleButtonForm(values, resetForm)}
                >
                  <span>Отказать</span>
                  <img src={arrowDown} slot='icon-right' alt='Icon right' />
                </Button>
              </section>
              {showForm &&
                <section className={style.section}>
                  <GxForm onGx-submit={handleSubmit} className={style.form}>
                    <Textarea
                      value={values.content}
                      onGx-input={handleChange}
                      name='content'
                      className={style.textarea}
                      rows='1'
                      placeholder='Комментарий'
                    />
                    <Button
                      className={style.submit}
                      onClick={resetForm}
                      type='submit'
                      disabled={values.content === ''}
                    >
                      Подтвердить
                    </Button>
                  </GxForm>
                </section>
              }
            </>
          )}
        </Formik>}
        <Title
          className={style.title}
          level='1'
          projectVariant='title_section'
        >
          Документы
        </Title>
      {getDocumentsByType(types, documents).map(item => (
        <OrderViews.DocumentListChecking
          key={item.id}
          isOther={item.type === 'OTHER'}
          isAction={order.state === STATUSES_STATE.QUOTE_AGREED}
          checked={checked}
          toggleCheck={toggleCheck}
          onDelete={handleDelete}
          handleUpload={handleUpload}
          {...item}
        />
      ))}
    </>
  )
}

export default OrderDocuments;