import React from 'react';
import { useStoreon } from 'storeon/react';
import { useModal } from '../../hooks';
import { MODALS } from '../../const';
import IndividualQuote from './IndividualQuote'
import IntegrationCheck from './IntegrationCheck'
import OrderListFilter from './OrderListFilter'
import OrderListExport from './OrderListExport'
import OrderStatus from './OrderStatus'
import SetUnderwriter from './SetUnderwriter'
import CryptoProModal from './CryptoProModal';
import DocumentChecking from './DocumentChecking';

const MODAL_TYPES = {
  [MODALS.individualQuoteModal]: IndividualQuote,
  [MODALS.integrationCheckModal]: IntegrationCheck,
  [MODALS.orderListFilterModal]: OrderListFilter,
  [MODALS.orderListExportModal]: OrderListExport,
  [MODALS.orderStatusModal]: OrderStatus,
  [MODALS.setUnderwriterModal]: SetUnderwriter,
  [MODALS.cryptoProModal]: CryptoProModal,
  [MODALS.documentCheckingModal]: DocumentChecking,
  empty: () => null,
};

const Modals = () => {
  const { closeModal } = useModal();
  const { showModal, props } = useStoreon('showModal', 'props');
  const CurrentModal = MODAL_TYPES[showModal || 'empty'];
  return <CurrentModal isOpen={showModal !== null} onClose={closeModal} {...props} />;
};

export default Modals