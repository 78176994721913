import React from 'react';
import ReactPaginate from 'react-paginate';
import classNames from 'classnames'
import Select from '../Select/index';
import styles from './styles/index.module.scss';

const Paginator = ({
  paginateData
}) => {
  return (
    <>
      <div className={styles.paging_div}>
        <div>
          <ReactPaginate
            breakLabel='...'
            onPageChange={paginateData.handlePageClick}
            pageCount={paginateData.totalPages}
            renderOnZeroPageCount={null}
            previousLabel='<'
            nextLabel='>'
            forcePage={paginateData.activePage}
            containerClassName={styles.paging}
            pageClassName={classNames([styles.item_page, styles.pagination_page])}
            previouslinkClassName={classNames([styles.item_page, styles.paging__link__previous])}
            disabledClassName={classNames([styles.item_page, styles.paging__link__disabled])}
            activeClassName={classNames([styles.item_page, styles.paging__link__active])}
          />
        </div>
        <Select
          className={styles.select}
          value={paginateData.itemLimit}
          hoist
          list={paginateData.itemLimits}
          onGx-change={(e) => paginateData.handleChangeItemLimits(e)}
        />
      </div>
    </>
  )
}

export default Paginator;
