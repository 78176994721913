/* eslint-disable */

import AbstractBaseApi from '../AbstractBaseApi';
import { factorsSerializer, ordersListSerializer, ordersFinReportSerializer } from './serializers'
import { formatTradeCalculation } from './serializers/formatDataToSend'

export default class OrdersApi extends AbstractBaseApi {
  getOrdersList = async (params) => {
    const res = await this.get('order/order/', params)
    return {serializedData: ordersListSerializer(res.data.results), data: res.data}
  }

  getOrdersGuarantyList = async () => {
    const res = await this.get('order/guaranty/')
    return res.data
  }

  getOrdersContractList = async () => {
    const res = await this.get('order/contract/')
    return res.data
  }

  getOrdersDocumentsList = async (params) => {
    const res = await this.get('order/order_document/', params)
    return res.data
  }

  getOrdersDocumentTypesList = async (params) => {
    const res = await this.get('order/document_type/', params)
    return res.data
  }

  createOrderDocument = async (params) => {
    const res = await this.post('order/order_document/', params)
    return res.data
  };

  updateOrderDocument = async (id, params) => {
    const res = await this.patch(`order/order_document/${id}/`, params)
    return res.data
  };

  deleteOrderDocument = async (id) => {
    const res = await this.delete(`order/order_document/${id}`)
    return res.data
  };

  uploadOrderFile = async (params) => {
    const res = await this.post('order/order_document/upload_file/', params)
    return res.data
  };

  getOrdersSpecialConditionList = async () => {
    const res = await this.get('order/order_special_condition/')
    return res.data
  }

  getOrderById = async (id) => {
    const res = await this.get(`order/order/${id}/`)
    return res.data
  }

  updateOrderById = async (id, data) => {
    const res = await this.patch(`order/order/${id}/`, data)
    return res.data
  }

  getOrdersPurchase = async () => {
    const res = await this.get('order/purchase/')
    return res.data
  }

  getOrdersPurchaseByOrder = async (params) => {
    const res = await this.get('order/purchase/', params)
    return res.data
  }

  getOrderGuarantyById = async (id) => {
    const res = await this.get(`order/guaranty/${id}/`)
    return res.data
  }

  getOrderContractById = async (id) => {
    const res = await this.get(`order/contract/${id}/`)
    return res.data
  }

  getOrderDocumentById = async (id) => {
    const res = await this.get(`order/order_document/${id}/`)
    return res.data
  }

  getOrderSpecialConditionById = async (id) => {
    const res = await this.get(`order/order_special_condition/${id}/`)
    return res.data
  }

  getOrderFactors = async (params) => {
    const res = await this.get('order/order_factors/', params)
    return factorsSerializer(res.data)
  }

  getAllStatuses = async () => {
    const res = await this.get('order/order/get_all_states/')
    return res.data
  }

  getFiltersValues = async  (params) => {
    const res = await this.get('order/order/get_filters_values/', params)
    return res.data
  }

  getExportTaskID = async (params) => {
    const res = await this.get('order/order/get_xls_task_id/', params)
    return res.data
  }

  getExportTaskResult = async (params) => {
    const res = await this.get('order/order/get_xls_task_result/', params)
    return res.data
  }

  getGuarantyStatusesList = async () => {
    const res = await this.get('order/order/get_guarantee_states/')
    return res.data
  }

  getOrderStatusesList = async () => {
    const res = await this.get('order/order/get_order_states/')
    return res.data
  }

  getOrderContestTypesList = async () => {
    const res = await this.get('limit/limit_fz/')
    return res.data
  }

  getOrderScoringTenders = async (params) => {
    const res = await this.get('order/oliver_wyman/', params)
    return res.data
  }

    getOrderScoringTendersBanksly = async (params) => {
    const res = await this.get('order/banksly/', params)
    return res.data
  }

  getComment = async (params) => {
    const res = await this.get('order/comment/', params)
    return res.data
  }

  postOrderComment = async (params) => {
    const res = await this.post('order/comment/', params)
    return res.data
  }

  getOrderOW = async (params) => {
    const res = await this.get('order/oliver_wyman/', params)
    return res.data
  }

  getOrderAccountingForms = async (params) => {
    const res = await this.get('order/accounting_forms/', params)
    return ordersFinReportSerializer(res.data)
  }

  getOrderRawAccountingForms = async (params = {}) => {
    const res = await this.get('order/accounting_forms/', params)
    return res.data
  }

  getOrdersQuote = async (params) => {
    const res = await this.get('order/order_quote/', params)
    return res.data
  }

  updateQuote = async (id, data) => {
    const res = await this.patch(`order/quote/${id}/`, data)
    return res.data
  }

  updateOrderQuote = async (orderQuoteId, params = {}) => {
    const res = await this.patch(`order/order_quote/${orderQuoteId}/`, params)
    return res.data
  }

  updateOrderContest = async (id, data) => {
    const res = await this.patch(`order/contest/${id}/`, data)
    return res.data
  }

  updateOrderContract = async (id, data) => {
    const res = await this.patch(`order/contract/${id}/`, data)
    return res.data
  }

  getPrincipalById = async (id) => {
    const res = await this.get(`limit/limit_principal/${id}/`)
    return res.data
  }

  calculateQuoteTrade = async (id, data) => {
    const formattedData = formatTradeCalculation(data)
    const res = await this.get(`order/quote/${id}/calculate_trade_quote/`, formattedData)
    return res.data
  }

  updateCalculatedQuoteTrade = async (id, data) => {
    const res = await this.patch(`order/quote/${id}/update_trade_quote/`, data)
    return res.data
  }

  createIndividualQuote = async (params) => {
    const res = await this.post(`order/quote/create_individual_quote/`, params)
    return res.data
  }
}
