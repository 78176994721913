import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { useStoreon } from 'storeon/react'
import uuid from 'uuid/dist/v4'
import OrderViews from '../../views/OrderViews'
import api from '../../api'
import { DIRECTIONS, MODALS, STATUSES_ID } from '../../const'
import { filterFormSchema, exportFormSchema } from '../../utils/SchemasFormik'
import { handleRequestError, isRoleJuniorUW } from '../../utils'
import { useModal } from '../../hooks'

const OrderList = ({ orderListHead, portfolioSection, pageTitle }) => {
  const [statusesList, setStatusesList] = useState([])
  const [contestTypesList, setContestTypesList] = useState([])
  const [orderListBody, setOrdersList] = useState([])
  const [sortDirection, setSortDirection] = useState(DIRECTIONS.asc)
  const [fieldToSort, setFieldToSort] = useState('')
  const [filterParams, setFilterParams] = useState({})
  const [exportParams, setExportParams] = useState({})
  const [isExportRequested, setIsExportRequested] = useState(false)
  const isResultPending = useRef(false)
  const [taskID, setTaskID] = useState(null)
  const [isFetchingOrders, setIsFetchingOrders] = useState(false)
  const { currentUser } = useStoreon('currentUser')
  const navigate = useNavigate()
  const mounted = useRef()
  const { closeModal, openModal } = useModal()
  const [activePage, setActivePage] = useState(1);
  const [itemLimit, setItemLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [minSum, setMinSum] = useState(0)
  const [maxSum, setMaxSum] = useState(0)
  const [minDate, setMinDate] = useState('')
  const [maxDate, setMaxDate] = useState('')
  const [minID, setMinID] = useState('')
  const [maxID, setMaxID] = useState('')

  const {
    getOrdersList,
    getAllStatuses,
    getGuarantyStatusesList,
    getOrderStatusesList,
    getOrderContestTypesList,
    getFiltersValues,
    getExportTaskID,
    getExportTaskResult,
  } = api.ordersApi


  const filtersErrorMesenge = {
    outOfRange: 'Вне диапазона (от 2000 до текущего года)',
    incorrectValues: 'Некорректно введены данные'
  }

  const initialFormValues = {
    state: '',
    contest__type: '',
    guarantySumMin: minSum,
    guarantySumMax: maxSum,
    yearMin: '',
    yearMax: '',
  }

  const initialFormExportValues = {
    dateMin: minDate,
    dateMax: maxDate,
    idMin: minID,
    idMax: maxID,
  }

  const isGuarantySumDefaultValues = (guarantySumMin, guarantySumMax) => {
    return (guarantySumMin === initialFormValues.guarantySumMin) && (guarantySumMax === initialFormValues.guarantySumMax)
  }

  const convertToSelectItems = (data, type) => {
    switch (type) {
      case 'status':
        return data.states.map(item => ({
          id: uuid(),
          value: item,
          name: STATUSES_ID[item]
        }));
      case 'contest':
        return data.map(({ id, fz }) => ({
          id,
          value: id,
          name: fz
        }));
      default:
        return null;
    }
  }

  const requestAndConvertOrders = (params = {page: activePage, limit: itemLimit}) => {
    setIsFetchingOrders(true)
    return getOrdersList(params)
      .then(({serializedData, data}) => {
        setOrdersList(serializedData);
        setTotalPages(Math.ceil(data.count/itemLimit))
      })
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetchingOrders(false))
  }

  const sortOrdersList = (field) => {
    setFieldToSort(field)
    setSortDirection(sortDirection === DIRECTIONS.asc ? DIRECTIONS.desc : DIRECTIONS.asc)
  }

  const modifiedOrderListHead = orderListHead.map(item => {
    return {
      ...item,
      cells: item.cells.map(cell => {
        return {
          ...cell,
          onClick: () => sortOrdersList(cell.sortName)
        }
      })
    }
  })

  const portfolioSectionsMap = {
    'orders': {
      underwriter: isRoleJuniorUW(currentUser) ? currentUser.id : undefined,
      doc_type: 'Order'
    },
    'guaranties': {
      underwriter: isRoleJuniorUW(currentUser) ? currentUser.id : undefined,
      doc_type: 'Guarantee'
    }
  }

  const filterOrdersList = ({ last_n, yearMin, yearMax, guarantySumMin, guarantySumMax, state, ...data }) => {
    const params = portfolioSectionsMap[portfolioSection] || {}
    setFilterParams({
      ...data,
      ...params,
      last_n,
      state,
      year: (!yearMin && !yearMax)
        ? undefined
        : `${yearMin || 1},${yearMax || new Date().getFullYear()}`,
      sum: isGuarantySumDefaultValues(guarantySumMin, guarantySumMax)
        ? undefined
        : `${guarantySumMin},${guarantySumMax}`,
    })
    closeModal()
  }

  const formik = useFormik({
    validationSchema: filterFormSchema(filtersErrorMesenge),
    initialValues: initialFormValues,
    onSubmit: filterOrdersList,
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
  })

  const handleOpenModal = () => openModal(MODALS.orderListFilterModal, {
    formik,
    statusesList,
    contestTypesList,
    resetForm: () => {
      formik.resetForm()
      setFilterParams({})
    },
    minValueBetween: minSum,
    maxValue: maxSum,
    isRendered: true,
  })

  const exportData = ({ dateMin, dateMax, idMin, idMax }) => {
    const params = portfolioSectionsMap[portfolioSection] || {}
    setIsExportRequested(true)
    setExportParams({
      ...params,
      date: (!dateMin && !dateMax)
        ? undefined
        : `${(dateMin === minDate) ? '*' : dateMin},${(dateMax === maxDate) ? '*' : dateMax}`,
      id: (!idMin && !idMax)
      ? undefined
      : `${(String(idMin) === String(minID)) ? '*' : idMin || '*'},${(String(idMax) === String(maxID)) ? '*' : idMax}`,
    })
  }

  useEffect(() => {
    if (isExportRequested) {
      getExportTaskID (exportParams)
      .then((data) => {
        setTaskID(data.task_id)
      })
      .catch(err => handleRequestError(err, navigate))
      .finally(setIsExportRequested(false))
    }
  }, [exportParams])

  const checkExportResult = () => {
    if (isResultPending) {
      getExportTaskResult ({'task_id': taskID})
      .then((data) => {
        if (data.status === 'SUCCESS') {
          isResultPending.current = true;
          window.open(data.path, '_blank', 'noopener,noreferrer');
        } else if (data.status === 'PENDING') {
          setTimeout(() => { checkExportResult(); }, 200)
        }
      })
      .catch(err => handleRequestError(err, navigate))
    }
  }
  
  useEffect(() => {
    checkExportResult()
  }, [taskID])

  const formikExport = useFormik({
    validationSchema: exportFormSchema(),
    initialValues: initialFormExportValues,
    onSubmit: () => {
      exportData(formikExport.values)
      formikExport.resetForm()
      setExportParams({})
      closeModal()      
    },
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
  })

  const handleOpenExportModal = () => openModal(MODALS.orderListExportModal, {
    formikExport,
    resetForm: () => {
      formikExport.resetForm()
    },
    handleTakeBlank: () => {
      setIsExportRequested(true)
      setExportParams({blank: true})
      closeModal()      
    },
    isRendered: true,
    minDate,
    maxDate,
    minID,
    maxID
  })

  const itemLimits = [
    { id: '10', value: '10', name: '10', checked: true },
    { id: '20', value: '20', name: '20' },
    { id: '30', value: '30', name: '30' },
  ];

  useEffect(() => {
    const getStatuses = () => {
      if (!portfolioSection) return getAllStatuses()
      if (portfolioSection === 'guaranties') return getGuarantyStatusesList()
      return getOrderStatusesList()
    }
    setItemLimit(10);
    const params = portfolioSectionsMap[portfolioSection] || {}

    Promise.all([
      getFiltersValues(params),
      getStatuses(),
      getOrderContestTypesList()
    ])
      .then(([FiltersData, statusesData, contestTypesData]) => {
        setMinSum(FiltersData.sum_min)
        setMaxSum(FiltersData.sum_max)        
        setMinDate(FiltersData.create_at_min)
        setMaxDate(FiltersData.create_at_max)        
        setMinID(FiltersData.id_min)
        setMaxID(FiltersData.id_max)        
        const convertedStatusesList = convertToSelectItems(statusesData, 'status')
        const convertedContestTypesList = convertToSelectItems(contestTypesData, 'contest')
        setStatusesList(convertedStatusesList)
        setContestTypesList(convertedContestTypesList)
      })
      .catch(err => handleRequestError(err, navigate))
  }, [])

  const recalc = () => {
    const params = portfolioSectionsMap[portfolioSection] || {}
    params.page = activePage + 1;
    params.limit = itemLimit;
    if (!mounted.current) {
      mounted.current = true
    } else {
      requestAndConvertOrders({
        ordering: sortDirection === DIRECTIONS.asc
          ? fieldToSort
          : `-${fieldToSort}`,
        ...params,
        ...filterParams,
      })
    }
  }

  useEffect(() => {
    recalc()
  }, [sortDirection, fieldToSort, activePage])

  useEffect(() => {
    if (activePage !== 0) {
      setActivePage(0);
    } else {
      recalc()
    };
  }, [filterParams, itemLimit]) 

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setActivePage(newOffset);
  };

  const handleChangeItemLimits = (e) => {
    setItemLimit(Number(e.target.value));
  }

  return (
    <div>
      <OrderViews.List
        title={pageTitle}
        tableHead={modifiedOrderListHead}
        tableBody={orderListBody}
        sortDirection={sortDirection}
        toggleModal={handleOpenModal}
        toggleExportModal={handleOpenExportModal}
        fieldToSort={fieldToSort}
        isFetching={isFetchingOrders}
        paginateData={{activePage, totalPages, itemLimit, itemLimits, handlePageClick, handleChangeItemLimits}}
      />
    </div>
  )
}

export default OrderList
