export const PATHS = {
  HOME: {
    path: '/*',
    link: '/',
    exact: true,
  },
  ORDERS: {
    path: '/order/*',
    link: '/order/'
  },
  ORDER_UNDERWRITING: {
    path: '/underwriting/',
    pathWithParam: '/underwriting/:orderId',
    link: '/order/underwriting/',
  },
  ORDER_PURCHASE: {
    path: '/purchase/',
    pathWithParam: '/purchase/:orderId',
    link: '/order/purchase/',
  },
  ORDER_FINREPORTING: {
    path: '/finreporting/',
    pathWithParam: '/finreporting/:orderId',
    link: '/order/finreporting/',
  },
  ORDER_RATING: {
    path: '/rating/',
    pathWithParam: '/rating/:orderId',
    link: '/order/rating/',
  },
  ORDER_LIMITS: {
    path: '/limits/',
    pathWithParam: '/limits/:orderId',
    link: '/order/limits/',
  },
  ORDER_STOPFACTORS: {
    path: '/stopfactors/',
    pathWithParam: '/stopfactors/:orderId',
    link: '/order/stopfactors/',
  },
  ORDER_SCORING: {
    path: '/scoring/',
    pathWithParam: '/scoring/:orderId',
    link: '/order/scoring/',
  },
  ORDER_DOCUMENTS: {
    path: '/documents/',
    pathWithParam: '/documents/:orderId',
    link: '/order/documents/',
  },
  ORDER_QUOTATION: {
    path: '/quotation/',
    pathWithParam: '/quotation/:orderId',
    link: '/order/quotation/',
  },
  ORDER_HISTORY: {
    path: '/history/',
    pathWithParam: '/history/:orderId',
    link: '/order/history/',
  },

  BANKS: {
    path: '/banks/*',
    link: '/banks/',
    exact: true
  },
  BANK_DOCUMENTS: {
    path: '/documents/',
    link: '/banks/documents/',
    pathWithParam: '/documents/:bankId',
  },
  BANK_MATRIX: {
    path: '/matrix/',
    link: '/banks/matrix/',
    pathWithParam: '/matrix/:bankId',
  },
  BANK_LIMITS: {
    path: '/limits/',
    link: '/banks/limits/',
    pathWithParam: '/limits/:bankId',
  },
  PRINCIPAL: {
    path: '/principal/*',
    link: '/principal/',
  },
  PRINCIPAL_INFO: {
    path: '/info/',
    pathWithParam: '/info/:principalId',
    link: '/principal/info/',
  },
  PRINCIPAL_COOWNERS: {
    path: '/coowners/',
    pathWithParam: '/coowners/:principalId',
    link: '/principal/coowners/',
  },
  PRINCIPAL_RELATIONSHIP: {
    path: '/relationship/',
    pathWithParam: '/relationship/:principalId',
    link: '/principal/relationship/',
  },
  PRINCIPAL_DOCUMENTS: {
    path: '/documents/',
    pathWithParam: '/documents/:principalId',
    link: '/principal/documents/',
  },
  PRINCIPAL_LIMITS: {
    path: '/limits/',
    pathWithParam: '/limits/:principalId',
    link: '/principal/limits/',
  },
  PRINCIPAL_RATING: {
    path: '/rating/',
    pathWithParam: '/rating/:principalId',
    link: '/principal/rating/',
  },
  PRINCIPAL_FINREPORTING: {
    path: '/finreporting/',
    pathWithParam: '/finreporting/:principalId',
    link: '/principal/finreporting/',
  },
  PRINCIPAL_PORTFOLIO: {
    path: '/portfolio/',
    pathWithParam: '/portfolio/:principalId',
    link: '/principal/portfolio/',
  },
  HANDBOOKS: {
    path: '/handbooks/*',
    link: '/handbooks/',
  },
  HANDBOOK_BLACKLIST: {
    path: '/blacklist/',
    link: '/handbooks/blacklist/',
  },
  HANDBOOK_STOPFACTORS: {
    path: '/stopfactors/',
    link: '/handbooks/stopfactors/',
  },
  HANDBOOK_PRESIGNALS: {
    path: '/presignals/',
    link: '/handbooks/presignals/',
  },
  LIMITS: {
    path: '/limits/*',
    link: '/limits/',
  },
  LIMITS_FZ: {
    path: '/fz/',
    link: '/limits/fz/',
  },
  LIMITS_PRINCIPALS: {
    path: '/principal/',
    link: '/limits/principal/',
  },
  LIMITS_BANKS: {
    path: '/banks/',
    link: '/limits/banks/',
  },
  PORTFOLIO: {
    path: '/portfolio/*',
    link: '/portfolio/',
  },
  PORTFOLIO_ORDERS: {
    path: '/orders/',
    link: '/portfolio/orders/',
  },
  PORTFOLIO_GUARANTIES: {
    path: '/guaranties/',
    link: '/portfolio/guaranties/',
  },
  UNDERWRITERS: {
    path: '/underwriters/*',
    link: '/underwriters/',
  },
  FAQ: {
    path: '/faq/'
  },
  CHAT: {
    path: '/chat/'
  },
  NOTIFICATIONS: {
    path: '/notifications',
  },
  PROFILE: {
    path: '/profile/'
  },
  REGISTRATION: {
    path: '/registration/',
  },
  AUTHORIZATION: {
    path: '/authorization/',
  },
  RECOVER_PASSWORD: {
    path: '/recoverPassword/',
  },
  FORGOT_PASSWORD: {
    path: '/forgotPassword/',
  },
  TERMS_OF_SERVICE: {
    path: '/termsOfService/',
  },
  ERROR404: {
    path: '/404/',
  },
  ERROR500: {
    path: '/500/',
  }
}

export const MS_BEFORE_REDIRECT = 5000

export const DIRECTIONS = {
  asc: 'asc',
  desc: 'desc'
}

export const STATUSES_PULL = [
  ['Создана', 'Новая', 'Андеррайтинг в работе', 'Андеррайтинг новая заявка'],
  ['Котировка направлена'],
  ['Котировка согласована'],
  ['Выпуск гарантии запрошен'],
  ['Гарантия действующая'],
]

export const RATINGS = [-2, -1, 0, 1, 2]

export const FACTORS_TYPES = {
  FORCED: 'FORCED',
  STOP: 'STOP'
}

export const STATUSES_ID = {
  CREATED: 'Создана',
  SENT: 'Отправлена',

  UNDERWRITING_A_NEW_APPLICATION: 'Андеррайтинг новая заявка',
  UNDERWRITING_IN_PROGRESS: 'Андеррайтинг в работе',
  UNDERWRITING_REQUERY: 'Андеррайтинг дозапрос',
  UNDERWRITING_REFUSAL: 'Андеррайтинг отказ',

  QUOTE_AUTO: 'Котировка авто',
  QUOTE_AUCTION: 'Котировка торг',
  QUOTE_INDIVIDUAL: 'Котировка индивид',
  QUOTE_SENT: 'Котировка направлена',
  QUOTE_AGREED: 'Котировка согласована',
  QUOTE_REDEFINED: 'Котировка обновление',
  QUOTE_REFUSAL: 'Котировка отказ',

  IN_ARCHIVE: 'Заявки в архиве',

  DOCUMENTS_REQUERY: 'Документы дозапрос',
  DOCUMENTS_SIGNATURE: 'Документы подпись',
  DOCUMENTS_REFUSAL: 'Документы отказ',

  GUARANTEE_ISSUED_PAYMENT_EXPECTED: 'Гарантия выпущена ожидается оплата',
  GUARANTEE_BENEFICIARY_CLAIM_RECEIVED: 'Гарантия получено требование бенефицара',
  GUARANTEE_LATE_PAYMENT_COMMISSION: 'Гарантия просрочка уплаты комиссии',
  GUARANTEE_PAYMENT_MADE_BENEFICIARY: 'Гарантия произведена выплата бенефициару',
  GUARANTEE_RECOURSE_CLAIM_MADE_AGAINST_PRINCIPAL: 'Гарантия предъявлено регрессное требование принципалу',
  GUARANTEE_CLAIM_PAYMENT_INSURANCE_COMPENSATION: 'Гарантия предъявлено требование на выплату страхового возмещения',
  GUARANTEE_PAYMENT_INSURANCE_COMPENSATION_MADE: 'Гарантия произведена выплата страхового возмещения',
  GUARANTEE_VALID: 'Гарантия действующая',
  GUARANTEE_ISSUE_REQUESTED: 'Выпуск гарантии запрошен',
  GUARANTEE_DISCLAIMER: 'Выпуск гарантии отказ',
  GUARANTEE_EXPIRED: 'Гарантия истек срок действия',
  GUARANTEE_TERMINATED: 'Гарантия прекращена',
}

export const STATUSES_STATE = {
  CREATED: 'CREATED',
  SENT: 'SENT',

  UNDERWRITING_A_NEW_APPLICATION: 'UNDERWRITING_A_NEW_APPLICATION',
  UNDERWRITING_IN_PROGRESS: 'UNDERWRITING_IN_PROGRESS',
  UNDERWRITING_REQUERY: 'UNDERWRITING_REQUERY',
  UNDERWRITING_REFUSAL: 'UNDERWRITING_REFUSAL',

  QUOTE_AUTO: 'QUOTE_AUTO',
  QUOTE_AUCTION: 'QUOTE_AUCTION',
  QUOTE_INDIVIDUAL: 'QUOTE_INDIVIDUAL',
  QUOTE_SENT: 'QUOTE_SENT',
  QUOTE_AGREED: 'QUOTE_AGREED',
  QUOTE_REDEFINED: 'QUOTE_REDEFINED',
  QUOTE_REFUSAL: 'QUOTE_REFUSAL',

  IN_ARCHIVE: 'IN_ARCHIVE',

  DOCUMENTS_REQUERY: 'DOCUMENTS_REQUERY',
  DOCUMENTS_SIGNATURE: 'DOCUMENTS_SIGNATURE',
  DOCUMENTS_REFUSAL: 'DOCUMENTS_REFUSAL',

  GUARANTEE_ISSUE_REQUESTED: 'GUARANTEE_ISSUE_REQUESTED',
  GUARANTEE_ISSUED_PAYMENT_EXPECTED: 'GUARANTEE_ISSUED_PAYMENT_EXPECTED',
  GUARANTEE_DISCLAIMER: 'GUARANTEE_DISCLAIMER',
  GUARANTEE_VALID: 'GUARANTEE_VALID',
  GUARANTEE_BENEFICIARY_CLAIM_RECEIVED: 'GUARANTEE_BENEFICIARY_CLAIM_RECEIVED',
  GUARANTEE_LATE_PAYMENT_COMMISSION: 'GUARANTEE_LATE_PAYMENT_COMMISSION',
  GUARANTEE_PAYMENT_MADE_BENEFICIARY: 'GUARANTEE_PAYMENT_MADE_BENEFICIARY',
  GUARANTEE_RECOURSE_CLAIM_MADE_AGAINST_PRINCIPAL: 'GUARANTEE_RECOURSE_CLAIM_MADE_AGAINST_PRINCIPAL',
  GUARANTEE_CLAIM_PAYMENT_INSURANCE_COMPENSATION: 'GUARANTEE_CLAIM_PAYMENT_INSURANCE_COMPENSATION',
  GUARANTEE_EXPIRED: 'GUARANTEE_EXPIRED',
  GUARANTEE_PAYMENT_INSURANCE_COMPENSATION_MADE: 'GUARANTEE_PAYMENT_INSURANCE_COMPENSATION_MADE',
  GUARANTEE_TERMINATED: 'GUARANTEE_TERMINATED',
}

export const QUOTE_TYPES_PULL = {
  AUTO: 'Авто',
  TRADE: 'Торг',
  INDIVID: 'Индивидуальная',
}

export const QUOTE_STATUSES_PULL = {
  CREATED: 'Создана',
  DIRECTED: 'Направлена',
  NOT_DIRECTED: 'Не направлена',
  UPDATED: 'Обновлена',
  AGREED: 'Согласована',
}

export const ROLES = {
  JUNIOR_UW: 'Андеррайтер',
  SENIOR_UW: 'Мастер-андеррайтер',
  PRINCIPAL: 'Принципал',
  AGENT: 'Агент',
  BANK: 'Банк',
}

export const ORDER_COMMENTS_PAGES = {
  UNDERWRITING: 'UNDERWRITING',
  RATING: 'RATING',
  LIMIT: 'LIMIT',
  QUOTE_INDIVID: 'QUOTE_INDIVID',
  QUOTE_AUCTION: 'QUOTE_AUCTION',
  DOCUMENT: 'DOCUMENT',
  PRINCIPAL_DOCUMENT: 'PRINCIPAL_DOCUMENT',
  PRINCIPAL_RATING: 'PRINCIPAL_RATING',
  BLACKLIST: 'BLACKLIST',
}

export const MODALS = {
  individualQuoteModal: 'individualQuoteModal',
  integrationCheckModal: 'integrationCheckModal',
  orderListFilterModal: 'orderListFilterModal',
  orderListExportModal: 'orderListExportModal',
  orderStatusModal: 'orderStatusModal',
  setUnderwriterModal: 'setUnderwriterModal',
  cryptoProModal: 'cryptoProModal',
  documentCheckingModal: 'documentCheckingModal'
};