/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../api';
import { formatNumberToSend, handleRequestError } from '../../utils';
import OrderViews from '../../views/OrderViews';
import purchaseUtils from './utils'
import Preloader from '../../views/Preloader'

const OrderPurchase = () => {
  const [order, setOrder] = useState({})
  const [purchaseOrder, setPurchaseOrder] = useState({})
  const [isFetching, setIsFetching] = useState(false)
  const { getOrderById, getOrdersPurchaseByOrder, updateOrderContract } = api.ordersApi
  const { orderId } = useParams()
  const navigate = useNavigate()

  const handleAcceptTotalSum = (contractId, name, value) => {
    const formattedValue = formatNumberToSend(value)
    const contract = { [name]: formattedValue }

    const handleUpdateOrderContract = () => updateOrderContract(contractId, contract)
    return toast.promise(handleUpdateOrderContract, {
      pending: 'Сохранение...',
      success: 'Цена успешно сохранена!'
    })
      .then(data => {
        setOrder(state => ({ ...state, data }))
        reloadData()
      })
      .catch(err => handleRequestError(err, navigate))
  }

  const reloadData = () => {
    setIsFetching(true)
    return Promise.all([getOrderById(orderId), getOrdersPurchaseByOrder({order: orderId})])
      .then(([orderData, purchaseData]) => {
        const totalSumInputAttr =
          orderData.contest
            ? {
              name: 'sum',
              handleAccept: (name, value) => handleAcceptTotalSum(orderData?.contest?.contract.id, name, value)
            }
            : undefined

        const serializedPurchaseData = purchaseUtils.convertPurchaseData({ ...orderData, ...purchaseData[0] }, totalSumInputAttr)

        setOrder(orderData)
        setPurchaseOrder(serializedPurchaseData)
      })
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }

  useEffect(() => {
    reloadData()
  }, [])

  return (
    <>
      <OrderViews.Header
        orderId={order.id}
        sum={order.sum}
        principal={order.principal}
        fz={order.contest?.fz?.fz}
        status={order.state}
      />
      {isFetching ?
        <Preloader />
        :
        <>
          <OrderViews.PurchaseShortInfo
            purchase={order?.contest?.contract?.purchase}
            pnt={order?.pnt}
            lot={order?.contest?.lot}
            fz={order.contest?.fz?.fz}
          />
          <OrderViews.PurchaseInfo
            purchaseOrder={purchaseOrder}
          />
        </>
      }
    </>
  )
}

export default OrderPurchase;
