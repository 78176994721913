import React from 'react';
import classNames from 'classnames';
import { GxIcon } from '@garpix/garpix-web-components-react';
import style from './styles/index.module.scss';

const ButtonIcon = ({
  className,
  classNameIcon = 'buttonIcon__icon',
  projectVariant = 'buttonIcon_default',
  iconSrc,
  ariaLabel,
  setRef,
  ...props
}) => {
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className={classNames({
        [style[projectVariant]]: true,
        [className]: !!className
      })}
      aria-label={ariaLabel}
      ref={setRef}
      {...props}
    >
      <GxIcon
        className={style[classNameIcon]}
        src={iconSrc}
      />
    </button>
  );
}

export default ButtonIcon;
