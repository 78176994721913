import React from 'react';
import classNames from 'classnames';
import Title from '../../Title';
import Button from '../../Button';
import { principalAlert } from '../../../images';
import style from './styles/index.module.scss';

const Header = ({
  principal,
  getURL=null,
  className,
  ...props
}) => {
  const handleOpenURL = () => {
    const newTab = window.open(principal?.legal_entity?.kontur_url, '_blank', 'noopener,noreferrer');
    if ( newTab ) newTab.opener = null;
  }
  return (
    <div>
      <section
        className={classNames({
          [style.principalHeader]: true,
          [className]: !!className
        })}
        {...props}
      >
        <div className={style.principalHeader__main}>
          <Title
            className={style.principalHeader__title}
            level='1'
            projectVariant='title_section'
          >
            <span>{principal?.title}</span>
            <img
              className={style.principalHeader__icon}
              src={principalAlert}
              alt=''
            />
          </Title>
          <span className={style.principalHeader__num}>{principal?.id}</span>
        </div>
      </section>
      {getURL ?
        <div className={style.principalHeader__button_link} >
          <Button
            onClick={handleOpenURL}
          >
            на kontur.ru
          </Button>
        </div>
      :
        <div />
      }
    </div>
  )
}

export default Header;
